const DEFAULT_SHOPIFY_LINK =
    'https://buy.getmnly.com/pages/adding-items-to-cart'
  //'https://buy.getmnly.com/cart/add?items[0][id]=43714368045244&items[0][quantity]=1&items[0][selling_plan]=5537136828&return_to=/checkout';

  // const DEFAULT_SHOPIFY_LINK =
  // 'https://mnly-store.myshopify.com/cart/clear?return_to=/cart/add?items[][id]=43714403631292%26items[][quantity]=1%26items[][selling_plan]=5292884156%26items[][id]=43714368045244%26items[][quantity]=1%26return_to=/checkout';

const SHOPIFY_CHECKOUT_URL =
  process.env.SHOPIFY_CHECKOUT_URL || DEFAULT_SHOPIFY_LINK;

export default SHOPIFY_CHECKOUT_URL;
